import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background: #fff;

  position: relative;
`;

export const BackButtonContainer = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
`;

export const Content = styled.div`
  width: 100%;
  padding: 5rem 1rem 1rem 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Button = styled.button`
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  border: none;
  background: #ff9000;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  transition: background-color 0.2s;

  &:hover {
    background: #ff9000;
    opacity: 0.8;
  }

  &:disabled {
    background: #ff9000;
    opacity: 0.5;
  }
`;
