import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { TabPanel } from '../../../components/infra/TabPanel';
import { Start } from './Start';
import { SignIn } from './SignIn';
import { SignUp } from './SignUp';
import { Home } from './Home';

import { Container } from './styles';

function Main() {
  const [value, setValue] = useState(0);

  const { customer } = useSelector(state => state.auth);

  const handleChangeTab = useCallback(tab => {
    setValue(tab);
  }, []);

  useEffect(() => {
    const tab = customer ? 3 : 0;
    setValue(tab);
  }, [customer]);

  return (
    <Container>
      <TabPanel value={value} index={0}>
        <Start handleChangeTab={handleChangeTab} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SignIn handleChangeTab={handleChangeTab} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SignUp handleChangeTab={handleChangeTab} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Home />
      </TabPanel>
    </Container>
  );
}

export { Main };
