import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 0 1rem;
  background: #fff;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BackButtonContainer = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
`;

export const FormContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const Input = styled.input`
  flex: 1;

  min-width: 200px;

  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;

  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: #000;
  }

  &::placeholder {
    color: #ccc;
  }

  &:focus::placeholder {
    color: #000;
  }
`;

export const Button = styled.button`
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;

  font-size: 1rem;
  font-weight: bold;
  color: #000;

  background: #fff;

  &:hover {
    cursor: pointer;
    background: #ccc;
  }

  &:focus {
    outline: none;
  }

  &:active {
    background: #000;
    color: #fff;
  }
`;
