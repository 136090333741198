import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Gototop } from '../../components/infra/Gototop';
import { Loading } from '../../components/infra/Loading';
import { Splash } from '../../components/infra/Splash';
import { companyRequest } from '../../store/modules/auth/actions';
import { Main } from './Main';

import { Container } from './styles';

const tokenExists = process.env.REACT_APP_TOKEN;

function Home({ match }) {
  const { params } = match;
  const { token } = params;

  const dispatch = useDispatch();

  const { loading, company } = useSelector(state => state.auth);

  useEffect(() => {
    const credentials = { token: tokenExists || token || 'default' };
    window.localStorage.setItem('credentials', JSON.stringify(credentials));
    dispatch(companyRequest());
  }, [dispatch, token]);

  return (
    <Container>
      {company ? <Main /> : <Splash />}
      <Gototop />
      <Loading loading={loading} />
    </Container>
  );
}

export { Home };
