import React from 'react';
import { useSelector } from 'react-redux';
import { toFONE } from '../../../../../utils/masks';

import { Container, FieldContainer } from './styles';

function Profile() {
  const { customer } = useSelector(state => state.auth);
  return customer ? (
    <Container>
      <FieldContainer>
        <strong>Nome</strong>
        <span>{customer.nome}</span>
      </FieldContainer>
      <FieldContainer>
        <strong>Email</strong>
        <span>{customer.email}</span>
      </FieldContainer>
      <FieldContainer>
        <strong>Celular</strong>
        <span>{toFONE(customer.fone)}</span>
      </FieldContainer>
    </Container>
  ) : (
    <Container />
  );
}

export { Profile };
