import { format } from 'date-fns';
import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  company: null,
  customer: null,
  movements: [],
  products: [],
  score: 0,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/COMPANY_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@auth/COMPANY_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@auth/COMPANY_SUCCESS': {
        draft.loading = false;
        draft.company = action.payload.company;
        if (draft.customer && draft.customer.codempresa !== draft.company.id) {
          draft.customer = null;
        }
        break;
      }

      case '@auth/CUSTOMER_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@auth/CUSTOMER_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@auth/CUSTOMER_SUCCESS': {
        draft.loading = false;
        draft.customer = action.payload.customer;
        break;
      }

      case '@auth/STORE_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@auth/STORE_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@auth/STORE_SUCCESS': {
        draft.loading = false;
        draft.customer = action.payload.customer;
        break;
      }

      case '@auth/MOVEMENTS_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@auth/MOVEMENTS_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@auth/MOVEMENTS_SUCCESS': {
        draft.loading = false;
        draft.movements = action.payload.movements.map(movement => ({
          ...movement,
          time: format(
            new Date(movement.time.replace(' ', 'T')),
            'dd/MM/yyyy HH:mm'
          ),
          score:
            movement.type === 'C' ? `${movement.score}` : `-${movement.score}`,
        }));
        draft.score = action.payload.movements.reduce((total, movement) => {
          if (movement.type === 'C') return total + movement.score;

          return total - movement.score;
        }, 0);

        break;
      }

      case '@auth/PRODUCTS_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@auth/PRODUCTS_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@auth/PRODUCTS_SUCCESS': {
        draft.loading = false;
        draft.products = action.payload.products;
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.loading = false;
        draft.customer = null;
        draft.movements = [];
        draft.products = [];
        draft.score = 0;
        break;
      }

      case '@starting': {
        draft.loading = false;
        draft.company = null;
        break;
      }

      default:
    }
  });
}
