import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import App from './App';

// import * as serviceWorkerRegistration from './serviceWorker';

ReactDOM.render(
  <SnackbarProvider
    maxSnack={3}
    hideIconVariant
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
  >
    <App />
  </SnackbarProvider>,
  document.getElementById('root')
);

// serviceWorkerRegistration.register();
