import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HistoryIcon from '@material-ui/icons/History';
import ReorderIcon from '@material-ui/icons/Reorder';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import { BorderlessButton } from '../../../../components/infra/BorderlessButton';

import {
  Container,
  BackButtonContainer,
  ScoreContainer,
  TabsContainer,
} from './styles';
import { TabPanel } from '../../../../components/infra/TabPanel';
import { signOut } from '../../../../store/modules/auth/actions';
import { Movements } from './Movements';
import { Products } from './Products';
import { Profile } from './Profile';

function Home() {
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);

  const { score } = useSelector(state => state.auth);

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  const handleChangeIndex = useCallback(index => {
    setValue(index);
  }, []);

  const handleSignOut = useCallback(() => {
    dispatch(signOut());
  }, [dispatch]);

  return (
    <Container>
      <BackButtonContainer>
        <BorderlessButton onClick={() => handleSignOut()}>
          <PowerSettingsNewOutlinedIcon />
        </BorderlessButton>
      </BackButtonContainer>

      <ScoreContainer>
        <span>{score}</span>
        <span>pontos</span>
      </ScoreContainer>

      <TabsContainer>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab icon={<HistoryIcon />} aria-label="phone" />
          <Tab icon={<ReorderIcon />} aria-label="favorite" />
          <Tab icon={<PermIdentityIcon />} aria-label="person" />
        </Tabs>
      </TabsContainer>

      <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0}>
          <Movements />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Products />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Profile />
        </TabPanel>
      </SwipeableViews>
    </Container>
  );
}

export { Home };
