import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { BorderlessButton } from '../../../../components/infra/BorderlessButton';

import {
  BackButtonContainer,
  Button,
  Container,
  FormContainer,
  Input,
} from './styles';
import { customerRequest } from '../../../../store/modules/auth/actions';
import { toFONE } from '../../../../utils/masks';
import { onlyNumbers } from '../../../../utils/onlyNumbers';

function SignIn({ handleChangeTab }) {
  const dispatch = useDispatch();

  const [fone, setFone] = useState('');

  const handleChangeFone = useCallback(event => {
    const { value } = event.target;
    setFone(toFONE(value));
  }, []);

  const handleEntrarClick = useCallback(
    phone => {
      const value = onlyNumbers(phone);
      dispatch(customerRequest({ phone: value }));
    },
    [dispatch]
  );

  return (
    <Container>
      <BackButtonContainer>
        <BorderlessButton onClick={() => handleChangeTab(0)}>
          <ArrowBackIosOutlinedIcon />
        </BorderlessButton>
      </BackButtonContainer>

      <FormContainer>
        <Input
          placeholder="Digite seu celular"
          value={fone}
          onChange={handleChangeFone}
        />
        <Button onClick={() => handleEntrarClick(fone)}>Entrar</Button>
      </FormContainer>
    </Container>
  );
}

export { SignIn };

SignIn.propTypes = {
  handleChangeTab: PropTypes.func.isRequired,
};
