import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function BorderlessButton({ children, ...rest }) {
  return (
    <Container type="button" {...rest}>
      {children}
    </Container>
  );
}

export { BorderlessButton };

BorderlessButton.propTypes = {
  children: PropTypes.node.isRequired,
};
