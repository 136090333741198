import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { productsRequest } from '../../../../../store/modules/auth/actions';

import { Container, Content, Image, Item } from './styles';

function Products() {
  const dispatch = useDispatch();

  const { products } = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(productsRequest());
  }, [dispatch]);

  return (
    <Container>
      {products.map(item => (
        <Item key={item.id}>
          <Content>
            <span>{item.name}</span>
            <span>{`${item.price} pontos`}</span>
            <span>{`${item.available} disponíveis`}</span>
          </Content>

          <Image src={item.url} alt={item.name} />
        </Item>
      ))}
    </Container>
  );
}

export { Products };
