import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 48px - calc(100vh / 4));
  overflow-y: auto;

  padding: 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const FieldContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;
