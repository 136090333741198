import styled from 'styled-components';

export const Container = styled.ul`
  width: 100%;
  height: calc(100vh - 48px - calc(100vh / 4));
  overflow-y: auto;

  display: flex;
  flex-direction: column;

  padding: 1rem 0;
`;

export const Item = styled.li`
  width: 100%;
  padding: 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  border-top: 1px solid rgba(149, 149, 149, 0.1);
  background-color: #fff;
`;

export const Content = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  span: first-child {
    font-size: 0.775rem;
    font-weight: 500;
    text-transform: capitalize;
  }

  span: last-child {
    font-size: 0.775rem;
    font-weight: 500;
  }
`;

export const Image = styled.img`
  width: 96px;
  height: 96px;

  border: 1px solid rgba(149, 149, 149, 0.4);
  border-radius: 6px;
  background-size: cover;
`;
