import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background: #fff;

  position: relative;

  display: flex;
  flex-direction: column;
`;

export const BackButtonContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const ScoreContainer = styled.div`
  width: 100%;
  height: calc(100vh / 4);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span:first-child {
    font-size: 3rem;
    font-weight: 700;

    color: #000;
  }
`;

export const TabsContainer = styled.div`
  width: 100%;
  height: 48px;
`;
