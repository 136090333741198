export function companyRequest() {
  return {
    type: '@auth/COMPANY_REQUEST',
  };
}

export function companyFailure() {
  return {
    type: '@auth/COMPANY_FAILURE',
  };
}

export function companySuccess(company) {
  return {
    type: '@auth/COMPANY_SUCCESS',
    payload: { company },
  };
}

export function customerRequest({ phone }) {
  return {
    type: '@auth/CUSTOMER_REQUEST',
    payload: { phone },
  };
}

export function customerFailure() {
  return {
    type: '@auth/CUSTOMER_FAILURE',
  };
}

export function customerSuccess(customer) {
  return {
    type: '@auth/CUSTOMER_SUCCESS',
    payload: { customer },
  };
}

export function storeRequest({
  nome,
  dtnascimento,
  sexo,
  cpfcnpj,
  cep,
  uf,
  codigoibgeuf,
  cidade,
  codigoibgecidade,
  bairro,
  logradouro,
  numero,
  complemento,
  fone,
  email,
}) {
  return {
    type: '@auth/STORE_REQUEST',
    payload: {
      nome,
      dtnascimento,
      sexo,
      cpfcnpj,
      cep,
      uf,
      codigoibgeuf,
      cidade,
      codigoibgecidade,
      bairro,
      logradouro,
      numero,
      complemento,
      fone,
      email,
    },
  };
}

export function storeFailure() {
  return {
    type: '@auth/STORE_FAILURE',
  };
}

export function storeSuccess(customer) {
  return {
    type: '@auth/STORE_SUCCESS',
    payload: { customer },
  };
}

export function movementsRequest({ customer }) {
  return {
    type: '@auth/MOVEMENTS_REQUEST',
    payload: { customer },
  };
}

export function movementsFailure() {
  return {
    type: '@auth/MOVEMENTS_FAILURE',
  };
}

export function movementsSuccess(movements) {
  return {
    type: '@auth/MOVEMENTS_SUCCESS',
    payload: { movements },
  };
}

export function productsRequest() {
  return {
    type: '@auth/PRODUCTS_REQUEST',
  };
}

export function productsFailure() {
  return {
    type: '@auth/PRODUCTS_FAILURE',
  };
}

export function productsSuccess(products) {
  return {
    type: '@auth/PRODUCTS_SUCCESS',
    payload: { products },
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function starting() {
  return {
    type: '@starting',
  };
}
