import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Container,
  HeaderContainer,
  ActionsContainer,
  SignInButton,
  SignUpButton,
} from './styles';

function Start({ handleChangeTab }) {
  const { company } = useSelector(state => state.auth);

  return (
    <Container>
      <HeaderContainer>
        <span>fidelidade</span>
        <span>{company.name}</span>
      </HeaderContainer>
      <ActionsContainer>
        <SignInButton type="button" onClick={() => handleChangeTab(1)}>
          Acessar
        </SignInButton>
        <SignUpButton type="button" onClick={() => handleChangeTab(2)}>
          Cadastrar-se
        </SignUpButton>
      </ActionsContainer>
    </Container>
  );
}

export { Start };

Start.propTypes = {
  handleChangeTab: PropTypes.func.isRequired,
};
