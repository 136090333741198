import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 0 1rem;
  background: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 3rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    font-size: 2rem;
    font-weight: 700;
    color: #000;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SignInButton = styled.button`
  width: 100%;
  height: 3rem;
  border: 0;
  border-radius: 0.5rem;
  background: transparent;
  color: #000;
  border: 1px solid #011133;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const SignUpButton = styled.button`
  width: 100%;
  height: 3rem;
  border: 0;
  border-radius: 0.5rem;
  background: #e91e63;
  color: #fff;
  border: 1px solid #011133;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;
