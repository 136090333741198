import { takeLatest, put, all, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  company,
  customer,
  movements,
  products,
  store,
} from '../../../services/application';

import {
  companyFailure,
  companySuccess,
  customerFailure,
  customerSuccess,
  movementsFailure,
  movementsSuccess,
  productsFailure,
  productsSuccess,
  starting,
  storeFailure,
  storeSuccess,
} from './actions';

export function* requestCompany() {
  try {
    const result = yield call(company);
    if (result.success) {
      yield put(companySuccess(result.data));
    } else {
      yield put(companyFailure());
      toast.error(result.message);
    }
  } catch (err) {
    yield put(companyFailure());
    toast.error('Erro ao buscar dados da empresa');
  }
}

export function* requestCustomer({ payload }) {
  try {
    const { phone } = payload;
    const result = yield call(customer, { phone });
    if (result.success) {
      yield put(customerSuccess(result.data));
    } else {
      yield put(customerFailure());
      toast.error(result.message);
    }
  } catch (err) {
    yield put(customerFailure());
    toast.error('Erro ao buscar dados do cliente');
  }
}

export function* requestStore({ payload }) {
  try {
    const {
      nome,
      dtnascimento,
      sexo,
      cpfcnpj,
      cep,
      uf,
      codigoibgeuf,
      cidade,
      codigoibgecidade,
      bairro,
      logradouro,
      numero,
      complemento,
      fone,
      email,
    } = payload;
    const result = yield call(store, {
      nome,
      dtnascimento,
      sexo,
      cpfcnpj,
      cep,
      uf,
      codigoibgeuf,
      cidade,
      codigoibgecidade,
      bairro,
      logradouro,
      numero,
      complemento,
      fone,
      email,
    });
    if (result.success) {
      yield put(storeSuccess(result.data));
    } else {
      yield put(storeFailure());
      toast.error(result.message);
    }
  } catch (err) {
    yield put(storeFailure());
    toast.error(
      'Falha ao tentar enviar os dados, favor verificar com um atendente.'
    );
  }
}

export function* requestMovements({ payload }) {
  try {
    const { customer: customerId } = payload;
    const result = yield call(movements, { customerId });
    if (result.success) {
      yield put(movementsSuccess(result.data));
    } else {
      yield put(movementsFailure());
      toast.error(result.message);
    }
  } catch (err) {
    yield put(movementsFailure());
    toast.error('Erro ao buscar movimentos do cliente');
  }
}

export function* requestProducts() {
  try {
    const result = yield call(products);
    if (result.success) {
      yield put(productsSuccess(result.data));
    } else {
      yield put(productsFailure());
      toast.error(result.message);
    }
  } catch (err) {
    yield put(productsFailure());
    toast.error('Erro ao buscar produtos');
  }
}

export function* start() {
  yield put(starting());
}

export default all([
  takeLatest('@auth/COMPANY_REQUEST', requestCompany),
  takeLatest('@auth/CUSTOMER_REQUEST', requestCustomer),
  takeLatest('@auth/STORE_REQUEST', requestStore),
  takeLatest('@auth/MOVEMENTS_REQUEST', requestMovements),
  takeLatest('@auth/PRODUCTS_REQUEST', requestProducts),
  takeLatest('persist/REHYDRATE', start),
]);
