import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HistoryIcon from '@material-ui/icons/History';

import { movementsRequest } from '../../../../../store/modules/auth/actions';
import { Container, Item } from './styles';

function Movements() {
  const dispatch = useDispatch();

  const { customer, movements } = useSelector(state => state.auth);

  useEffect(() => {
    if (customer) {
      dispatch(movementsRequest({ customer: customer.codcliente }));
    }
  }, [dispatch, customer]);

  return (
    <Container>
      {movements.map(item => (
        <Item key={item.id}>
          <HistoryIcon />
          <span>{item.time}</span>
          <span>{item.score}</span>
        </Item>
      ))}
    </Container>
  );
}

export { Movements };
