import MaskService from 'react-masked-text/src/mask-service';

const toCPF = value => {
  const document = MaskService.toMask('cpf', value);
  return document;
};

const toCEP = value => {
  const document = MaskService.toMask('zip-code', value);
  return document;
};

const toFONE = value => {
  const document = MaskService.toMask('cel-phone', value);
  return document;
};

export { toCPF, toCEP, toFONE };
