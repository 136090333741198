import styled from 'styled-components';

import PropTypes from 'prop-types';

export const Container = styled.button`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 99;

  border: none;
  outline: none;
  cursor: pointer;

  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  width: 40px;
  height: 40px;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }

  ${({ isVisible }) =>
    isVisible
      ? `
      opacity: 1;
      visibility: visible;
  `
      : `
    opacity: 0;
    visibility: hidden;
  `}

  transition: all 0.4s ease-in-out;
`;

export const Button = styled.button`
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 15px;
  border-radius: 50%;
  transition: 0.3s;
`;

Container.propTypes = {
  isVisible: PropTypes.bool,
};

Button.defaultProps = {
  isVisible: false,
};
