import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { validate } from 'gerador-validador-cpf';
import { BorderlessButton } from '../../../../components/infra/BorderlessButton';

import { findCEP } from '../../../../utils/findCEP';
import { toCPF, toCEP, toFONE } from '../../../../utils/masks';
import { onlyNumbers } from '../../../../utils/onlyNumbers';

import { BackButtonContainer, Button, Container, Content } from './styles';
import { storeRequest } from '../../../../store/modules/auth/actions';

const sexos = [
  { value: 'M', label: 'Masculino' },
  { value: 'F', label: 'Feminino' },
];

function SignUp({ handleChangeTab }) {
  const dispatch = useDispatch();

  const [nome, setNome] = useState('');
  const [nomeError, setNomeError] = useState(false);
  const [dtnascimento, setDtnascimento] = useState('');
  const [dtnascimentoError, setDtnascimentoError] = useState(false);
  const [sexo, setSexo] = useState('');
  const [sexoError, setSexoError] = useState(false);
  const [cpfcnpj, setCpfcnpj] = useState('');
  const [cpfcnpjError, setCpfcnpjError] = useState(false);
  const [cep, setCep] = useState('');
  const [cepError, setCepError] = useState(false);
  const [uf, setUf] = useState('');
  const [ufError, setUfError] = useState(false);
  const [codigoibgeuf, setCodigoibgeuf] = useState('');
  const [cidade, setCidade] = useState('');
  const [cidadeError, setCidadeError] = useState(false);
  const [codigoibgecidade, setCodigoibgecidade] = useState('');
  const [bairro, setBairro] = useState('');
  const [bairroError, setBairroError] = useState(false);
  const [logradouro, setLogradouro] = useState('');
  const [logradouroError, setLogradouroError] = useState(false);
  const [numero, setNumero] = useState('');
  const [numeroError, setNumeroError] = useState(false);
  const [complemento, setComplemento] = useState('');
  const [fone, setFone] = useState('');
  const [foneError, setFoneError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const { loading } = useSelector(state => state.auth);

  const handleChangeNome = useCallback(event => {
    const { value } = event.target;
    setNome(value);
    setNomeError(false);
  }, []);

  const handleChangeDtnascimento = useCallback(event => {
    const { value } = event.target;
    setDtnascimento(value);
    setDtnascimentoError(false);
  }, []);

  const handleChangeSexo = useCallback(event => {
    const { value } = event.target;
    setSexo(value);
    setSexoError(false);
  }, []);

  const handleChangeCpfcnpj = useCallback(event => {
    const { value } = event.target;
    setCpfcnpj(toCPF(value));
    setCpfcnpjError(false);
  }, []);

  const handleChangeCep = useCallback(async event => {
    const { value } = event.target;
    setCep(toCEP(value));
    setCepError(false);

    if (value.length === 9) {
      const data = await findCEP(value);

      setUf(data.uf);
      setCodigoibgeuf(data.coduf);
      setCidade(data.cidade);
      setCodigoibgecidade(data.codcidade);
      setBairro(data.bairro);
      setLogradouro(data.rua);

      setUfError(false);
      setCidadeError(false);
      setBairroError(false);
      setLogradouroError(false);
    }
  }, []);

  const handleChangeLogradouro = useCallback(event => {
    const { value } = event.target;
    setLogradouro(value);
    setLogradouroError(false);
  }, []);

  const handleChangeNumero = useCallback(event => {
    const { value } = event.target;
    setNumero(value);
    setNumeroError(false);
  }, []);

  const handleChangeComplemento = useCallback(event => {
    const { value } = event.target;
    setComplemento(value);
  }, []);

  const handleChangeBairro = useCallback(event => {
    const { value } = event.target;
    setBairro(value);
    setBairroError(false);
  }, []);

  const handleChangeFone = useCallback(event => {
    const { value } = event.target;
    setFone(toFONE(value));
    setFoneError(false);
  }, []);

  const handleChangeEmail = useCallback(event => {
    const { value } = event.target;
    setEmail(value.trim().toLowerCase());
    setEmailError(false);
  }, []);

  const handleSubmit = useCallback(
    async data => {
      const vnome = data.nome.trim().split(' ');
      const cnomeError = vnome.length <= 1;
      setNomeError(cnomeError);

      const [year, month, day] = data.dtnascimento.trim().split('-');
      const vdtnascimento = new Date(
        Number(year),
        Number(month) - 1,
        Number(day)
      );
      const cdtnascimentoError = vdtnascimento.toString() === 'Invalid Date';
      setDtnascimentoError(cdtnascimentoError);

      const vsexo = data.sexo.trim();
      const csexoError = vsexo.length === 0;
      setSexoError(csexoError);

      const vcpfcnpj = onlyNumbers(data.cpfcnpj);
      const ccpfcnpjError = vcpfcnpj.length === 0 || !validate(vcpfcnpj);
      setCpfcnpjError(ccpfcnpjError);

      const vcep = onlyNumbers(data.cep);
      const ccepError = vcep.length !== 8;
      setCepError(ccepError);

      const vuf = data.uf.trim();
      const cufError = vuf.length === 0;
      setUfError(cufError);

      const vcidade = data.cidade.trim();
      const ccidadeError = vcidade.length === 0;
      setCidadeError(ccidadeError);

      const vbairro = data.bairro.trim();
      const cbairroError = vbairro.length === 0;
      setBairroError(cbairroError);

      const vlogradouro = data.logradouro.trim();
      const clogradouroError = vlogradouro.length === 0;
      setLogradouroError(clogradouroError);

      const vnumero = data.numero.trim();
      const cnumeroError = vnumero.length === 0;
      setNumeroError(cnumeroError);

      const vfone = onlyNumbers(data.fone);
      const cfoneError = vfone.length !== 11;
      setFoneError(cfoneError);

      const vemail = data.email.trim();
      const cemailError = !/.+@.+\.[A-Za-z]+$/.test(vemail);
      setEmailError(cemailError);

      if (
        cnomeError ||
        cdtnascimentoError ||
        csexoError ||
        ccpfcnpjError ||
        ccepError ||
        cufError ||
        ccidadeError ||
        cbairroError ||
        clogradouroError ||
        cnumeroError ||
        cfoneError ||
        cemailError
      ) {
        return;
      }

      dispatch(
        storeRequest({
          nome: data.nome.trim(),
          dtnascimento: data.dtnascimento,
          sexo: vsexo,
          cpfcnpj: vcpfcnpj,
          cep: vcep,
          uf: vuf,
          codigoibgeuf: data.codigoibgeuf,
          cidade: vcidade,
          codigoibgecidade: data.codigoibgecidade,
          bairro: vbairro,
          logradouro: vlogradouro,
          numero: vnumero,
          complemento: data.complemento,
          fone: vfone,
          email: vemail,
        })
      );
    },
    [dispatch]
  );

  return (
    <Container>
      <BackButtonContainer>
        <BorderlessButton onClick={() => handleChangeTab(0)}>
          <ArrowBackIosOutlinedIcon />
        </BorderlessButton>
      </BackButtonContainer>

      <Content>
        <TextField
          required
          id="nome"
          label="Nome completo"
          type="text"
          variant="outlined"
          autoComplete="off"
          InputLabelProps={{
            shrink: true,
          }}
          value={nome}
          onChange={handleChangeNome}
          size="small"
          error={nomeError}
        />

        <TextField
          required
          id="dtnascimento"
          label="Data de nascimento"
          type="date"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={dtnascimento}
          onChange={handleChangeDtnascimento}
          size="small"
          error={dtnascimentoError}
        />

        <TextField
          required
          id="sexo"
          select
          label="Sexo"
          type="text"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={sexo}
          onChange={handleChangeSexo}
          size="small"
          error={sexoError}
        >
          {sexos.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          required
          id="cpfcnpj"
          label="CPF"
          type="text"
          variant="outlined"
          autoComplete="off"
          InputLabelProps={{
            shrink: true,
          }}
          value={cpfcnpj}
          onChange={handleChangeCpfcnpj}
          size="small"
          error={cpfcnpjError}
        />

        <TextField
          required
          id="cep"
          label="CEP"
          type="text"
          variant="outlined"
          autoComplete="off"
          InputLabelProps={{
            shrink: true,
          }}
          value={cep}
          onChange={handleChangeCep}
          size="small"
          error={cepError}
        />

        <TextField
          required
          disabled
          id="uf"
          label="UF"
          type="text"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={uf}
          size="small"
          error={ufError}
        />

        <TextField
          required
          disabled
          id="cidade"
          label="Cidade"
          type="text"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={cidade}
          size="small"
          error={cidadeError}
        />

        <TextField
          required
          id="bairro"
          label="Bairro"
          type="text"
          variant="outlined"
          autoComplete="off"
          InputLabelProps={{
            shrink: true,
          }}
          value={bairro}
          onChange={handleChangeBairro}
          size="small"
          error={bairroError}
        />

        <TextField
          required
          id="logradouro"
          label="Rua"
          type="text"
          variant="outlined"
          autoComplete="off"
          InputLabelProps={{
            shrink: true,
          }}
          value={logradouro}
          onChange={handleChangeLogradouro}
          size="small"
          error={logradouroError}
        />

        <TextField
          required
          id="numero"
          label="Número"
          type="text"
          variant="outlined"
          autoComplete="off"
          InputLabelProps={{
            shrink: true,
          }}
          value={numero}
          onChange={handleChangeNumero}
          size="small"
          error={numeroError}
        />

        <TextField
          id="complemento"
          label="Complemento"
          multiline
          minRows={2}
          variant="outlined"
          autoComplete="off"
          InputLabelProps={{
            shrink: true,
          }}
          value={complemento}
          onChange={handleChangeComplemento}
          size="small"
        />

        <TextField
          required
          id="fone"
          label="Telefone"
          type="text"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={fone}
          onChange={handleChangeFone}
          size="small"
          helperText="Por favor digite seu número de whatsapp"
          error={foneError}
        />

        <TextField
          required
          id="email"
          label="Conta de email"
          type="email"
          variant="outlined"
          autoComplete="off"
          InputLabelProps={{
            shrink: true,
          }}
          value={email}
          onChange={handleChangeEmail}
          size="small"
          helperText="Por favor digite seu melhor e-mail"
          error={emailError}
        />

        <Button
          type="button"
          disabled={loading}
          onClick={() => {
            handleSubmit({
              nome,
              dtnascimento,
              sexo,
              cpfcnpj,
              cep,
              uf,
              codigoibgeuf,
              cidade,
              codigoibgecidade,
              bairro,
              logradouro,
              numero,
              complemento,
              fone,
              email,
            });
          }}
        >
          <span>Enviar</span>
        </Button>
      </Content>
    </Container>
  );
}

export { SignUp };

SignUp.propTypes = {
  handleChangeTab: PropTypes.func.isRequired,
};
