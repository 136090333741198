import styled from 'styled-components';

export const Container = styled.button`
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  transition: 0.4s;
  background-color: transparent;
  border: none;
  cursor: pointer;

  > svg {
    font-size: 1.5rem;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
