import { urlBase, userCredentials } from './base';

export const company = async () => {
  const { token } = userCredentials();

  const url = `${urlBase}?t=${token}`;

  const response = await fetch(url, {
    method: 'GET',
  });

  return response.json();
};

export const customer = async ({ phone }) => {
  const { token } = userCredentials();

  const url = `${urlBase}/customer?t=${token}`;

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ phone }),
  });

  return response.json();
};

export const store = async ({
  nome,
  dtnascimento,
  sexo,
  cpfcnpj,
  cep,
  uf,
  codigoibgeuf,
  cidade,
  codigoibgecidade,
  bairro,
  logradouro,
  numero,
  complemento,
  fone,
  email,
}) => {
  const { token } = userCredentials();

  const url = `${urlBase}/store?t=${token}`;

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      nome,
      dtnascimento,
      sexo,
      cpfcnpj,
      cep,
      uf,
      codigoibgeuf,
      cidade,
      codigoibgecidade,
      bairro,
      logradouro,
      numero,
      complemento,
      fone,
      email,
    }),
  });

  return response.json();
};

export const movements = async ({ customerId }) => {
  const { token } = userCredentials();

  const url = `${urlBase}/movements?t=${token}`;

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ customer: customerId }),
  });

  return response.json();
};

export const products = async () => {
  const { token } = userCredentials();

  const url = `${urlBase}/products?t=${token}`;

  const response = await fetch(url, {
    method: 'GET',
  });

  return response.json();
};
