import styled from 'styled-components';

export const Container = styled.ul`
  width: 100%;
  height: calc(100vh - 48px - calc(100vh / 4));
  overflow-y: auto;

  padding: 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Item = styled.li`
  width: 100%;

  padding: 0.5rem 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  svg {
    font-size: 2rem;
  }

  span:nth-child(2) {
    flex: 1;
  }

  span:last-child {
    font-size: 1.2rem;
  }
`;
